/*
  jera jera design system
*/

import * as colors from './colors'

export const textFontSize = () => 20
export const gridSize = () => 4

/*
export const weight_light = 300
export const weight_book = 400
export const weight_medium = 500
export const weight_bold = 700
*/

// source sans pro (UI)
// 400,600,700,900

// source serif pro (Content)
// 400,600

// source code pro (Data)
// 500

/*
100    Extra Light or Ultra Light
200    Light or Thin
300    Book or Demi
400    Normal or Regular
500    Medium
600    Semibold, Demibold
700    Bold
800    Black, Extra Bold or Heavy
900    Extra Black, Fat, Poster or Ultra Black
*/

export const base = () =>`
  color: ${colors.text};
  font-style: normal;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
`

export const baseUI = (size, lineHeight) =>`
  ${base()}
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: ${size}px;
  line-height: ${lineHeight / size};
`

export const baseText = (size, lineHeight) =>`
  ${base()}
  font-family: 'source-serif-pro', serif;
  font-size: ${size}px;
  font-style: inherit;
  line-height: ${lineHeight / size};
`

export const baseCode = (size, lineHeight) =>`
  ${base()}
  font-family: 'Source Code Pro', sans-serif;
  font-size: ${size}px;
  font-style: inherit;
  font-weight: 600;
  line-height: ${lineHeight / size};
`

// typographic scales

export const U000 = () =>`
  ${baseUI(16, 16)}
  color: ${colors.N700};
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    color: ${colors.N750};
  }
  @media (hover: none) {
    background: none !important;
  }
`

export const C200 = () =>`
  ${baseCode(16, 24)/* 16 × 1.15 */}
  color: ${colors.code};
`

export const C300 = () =>`
  ${baseCode(20, 30)/* 20 × 1.15 */}
  color: ${colors.link};
`

export const T800 = () =>`
  ${baseText(61, 67.1)/* 61 × 1.1 */}
  color: ${colors.heading};
  font-weight: 600;
  letter-spacing: -0.02px;
  margin: 0;
`

export const T700 = () =>`
  ${baseText(48, 55)/* 48 × 1.15 */}
  color: ${colors.heading};
  font-weight: 600;
  letter-spacing: -0.02px;
  margin: 0;
`

export const T600 = () =>`
  ${baseText(39, 45)/* 39 × 1.15 */}
  color: ${colors.heading};
  font-weight: 600;
  letter-spacing: -0.02px;
  __margin: 0;
`

export const T500 = () =>`
  ${baseText(32, 37)/* 32 × 1.15 */}
  color: ${colors.heading};
  font-weight: 600;
  letter-spacing: -0.02px;
  __margin: 0;
`

export const T400 = () =>`
  ${baseText(25, 37.5)/* 25 × 1.5 */}
  color: ${colors.text};
  __margin: 0;
`

export const T300 = () =>`
  ${baseText(20, 30)/* 20 × 1.5 */}
  color: ${colors.text};
  __margin: 0;
`

// typography tokens
export const uiButton = U000()

export const heroHeading = T800()
export const heading1 = T700()
export const heading2 = T600()
export const heading3 = T500()
export const blockQuote = T400()
export const heroText = T400()
export const text = T300()
export const code = C300()
export const codeBlock = C200()
