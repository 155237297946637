import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import * as colors from './colors'

const StyledTopicBit = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  
  a, a:visited, a:active {
    color: ${colors.P600};
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 20px;
  }
`

const TopicBit = ({ label }) => {
  return (
    <StyledTopicBit>
      <Link to={`/${label}`}>{label}</Link>
    </StyledTopicBit>
  )
}

TopicBit.propTypes = {
  label: PropTypes.string.isRequired
}

export default TopicBit
