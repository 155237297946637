import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;  
`

export default ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
)
